<template>
  <div>
<!--    basic_expenses_by_ea-->
    <div v-if="userPermissions.indexOf('basic_expenses_by_ea_list') !== -1">
      <!-- 搜索-->
      <el-form :inline='true' label-position='right' class='table-search'>
        <el-form-item>
          <el-date-picker
              @change="handleQuery"
              v-model="selectInfo.month"
              type="month"
              align="right"
              unlink-panels
              placeholder="选择月份"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select @change="handleQuery" filterable clearable placeholder="选择红人" v-model="selectInfo.artist_id">
            <el-option :key="index" :label="item.nickname" :value="item.artist_id" v-for="(item,index) in kolList">
              <span style="float: left"> {{ item.nickname }} </span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click='handleQuery' type='primary' icon='el-icon-search'>查询</el-button>
        </el-form-item>
      </el-form>
      <div class="default-table">
        <el-table :data="dataList" ref="multipleTable" row-key="id"
                  v-loading="isLoading" border>
          <el-table-column
              type="selection"
              width="40">
          </el-table-column>
          <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
          <el-table-column align="center" label="月份"
                           show-overflow-tooltip prop="month">
            <template slot-scope='{row}'>
              {{
                parseTime(row.month, '{y}-{m}')
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="红人昵称" min-width="150" prop="nickname"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="right" label="金额" prop="amount" show-overflow-tooltip
                           :formatter="formatNumber"></el-table-column>
        </el-table>
        <br>
        <el-row>
          <el-col :span='24' style='text-align: right'>
            <Pagination :limit.sync='pagingData.page_size'
                        :page.sync='pagingData.current_page'
                        :total='pagingData.total'
                        @pagination='getList'/>
          </el-col>
        </el-row>

      </div>

    </div>
  </div>
</template>
<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import GroupSelect from '@/pages/team/components/GroupSelect.vue'
import Contextmenu from '@/pages/kolModel/components/Contextmenu.vue'
import { mapGetters } from 'vuex'
import { parseTime } from '@/utils'

export default {
  name: 'expense_index',
  components: {
    PageHeaderLayout, ArtistSearch, GroupSelect, Contextmenu
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      dataList: [],
      pagingData: {
        page_size: 20,
        current_page: 1,
        total: 0
      },
      selectInfo: {},
      kolList: [],
      isLoading: false,
      delIds: [],
      visible: false,
      file: [],
      wsStop: false,
      fileName: '',
      reloadData: false,
      amount: ''
    }
  },
  methods: {
    parseTime,
    async getKol() {
      this.kolList = await this.$api.modelGetKolList()
    },
    async getList() {
      Object.assign(this.selectInfo, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      })
      const { list, page_info } = await this.$api.kolGroupMonthlist(this.selectInfo)

      this.dataList = list
      this.pagingData = page_info
      this.isLoading = false
    },
    detailPage(row) {
      const { href } = this.$router.resolve({
        name: 'modelDetail',
        params: { id: row.artist_id },
        query: {
          year: row.month.slice(0, 4),
          month: row.month.slice(5, 7)
        }
      })
      window.open(href, '_blank')
    },
    formatNumber(row, col) {
      return this.$utils.numberFormat(row[col.property], 2, '.', ',', 'round')
    },

    handleQuery() {
      if (this.selectInfo.artist_id === '') {
        delete this.selectInfo.artist_id
      }
      if (this.selectInfo.month === null) {
        delete this.selectInfo.month
      }
      this.pagingData.current_page = 1
      this.getList()
    }
  },

  mounted() {
    this.handleQuery()
    this.getKol()

  }
}
</script>

<style lang="scss" scoped>
.addNewBox {
  width: 90%;
  margin: 0 auto;

  > * {
    padding: 10px;
  }
}


.download {
  font-size: 18px;
  margin-left: 12px;
  color: #ff3176;
  cursor: pointer;
  display: inline-grid !important;
}
</style>

